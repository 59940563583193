import React, { useState } from 'react';
import axios from 'axios';
import { ArrowRightIcon } from '@heroicons/react/solid';

export default function Newsletter() {
    const [email, setEmail] = useState<string>('');
    const [responseMessage, setResponseMessage] = useState<string>('');

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://api.pay.wakaglobal.com/api/v2/newspaper/email', { email });
            setResponseMessage('Thank you for subscribing!');
            setEmail('')
        } catch (error) {
            setResponseMessage('Subscription failed. Please try again.');
        }
    };

    return (
        <div className="flex flex-col gap-16 justify-center items-center h-[700px]">
            <div className="w-full flex justify-center items-center text-lg md:text-xl">NEWSLETTER</div>
            <div className="flex flex-col gap-5">
                <div className="w-full flex justify-center items-center text-xl md:text-6xl">Join the community</div>
                <div className="w-full flex justify-center items-center text-lg md:text-4xl">
                    Subscribe to our Bi-weekly Newsletter to stay up to date with our offers and services.
                </div>
            </div>

            <form className="w-10/12 md:w-1/2 flex flex-row rounded-full border-2 mt-5" onSubmit={handleSubmit}>
                <input
                    placeholder="Enter Your Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="p-4 md:px-10 bg-transparent w-9/12 md:text-2xl focus:outline-none border-none"
                />
                <button
                    type="submit"
                    className="bg-[#1D4A5F] p-2 w-3/12 flex rounded-full justify-center items-center"
                >
                    <ArrowRightIcon className="w-10 md:w-20" />
                </button>
            </form>

            {responseMessage && (
                <div className="w-full flex justify-center items-center text-lg md:text-xl mt-5">
                    {responseMessage}
                </div>
            )}
        </div>
    );
}
