import logo from '../assets/logo.png';
import bg_1 from '../assets/5.jpg';
import bg_2 from '../assets/1.jpg';
import bg_3 from '../assets/2.jpg';
import bg_4 from '../assets/10.jpg';
import bg_5 from '../assets/14.jpg';
import bg_6 from '../assets/24.jpg';
import bg_7 from '../assets/15.jpg';
import bg_8 from '../assets/19.jpg';
import bg_9 from '../assets/26.jpg';
import { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import AnimatedModal from './modal';
import NewsLetter from './newsletter';
import ContactUs from './contact_us';
import Gallery from './gallery';
import {PiDotsNineBold} from "react-icons/pi";


export default function LandingPage() {

    const images = [
        bg_6,
        bg_2,
        bg_1,
        bg_3,
        bg_4,
        bg_5,
    ];
    const images_mobile = [
        bg_6,
        bg_8,
        bg_9,
        bg_4,
        bg_5,
        bg_7
    ];

    const [currentArray, setCurrentArray] = useState(images);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 700px)');

        const handleScreenChange = (e: MediaQueryListEvent) => {
            if (e.matches) {
                setCurrentArray(images_mobile);
            } else {
                setCurrentArray(images);
            }
        };

        // Check on initial load
        if (mediaQuery.matches) {
            setCurrentArray(images_mobile);
        }

        // Add listener
        mediaQuery.addEventListener('change', handleScreenChange);

        // Cleanup listener on component unmount
        return () => {
            mediaQuery.removeEventListener('change', handleScreenChange);
        };
    }, []);

    const [index, setIndex] = useState(0);

    const [isContactModalOpen, setIsContactModalOpen] = useState(false);
    const [isNewsModalOpen, setIsNewsModalOpen] = useState(false);
    const [isGalleryModalOpen, setIsGalleryModalOpen] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 9000); // Change slide every 3 seconds
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="relative bg-dark-light w-full h-screen overflow-hidden flex items-center justify-center">
            <AnimatePresence initial={false}>
                <motion.div
                    key={index}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 1 }}
                    className="absolute w-full h-full flex items-center justify-center"
                >
                    <motion.img
                        src={currentArray[index]}
                        alt={`Slide ${index}`}
                        initial={{ scale: 1 }}
                        animate={{ scale: [1, 1.3] }}
                        transition={{ duration: 10, repeat: Infinity }}
                        className="w-full h-screen max-h-full object-cover"
                    />
                </motion.div>
            </AnimatePresence>
            <div className="absolute w-full h-full">
                <div
                    className="montserrat backdrop-brightness-50 h-screen flex flex-col items-center justify-between">
                    <nav className="flex w-full p-4 md:p-10 text-light justify-between items-center flex-row">
                        <img src={logo} alt="waka" className={'w-24 md:w-44'} />

                        <div className="flex flex-row items-center gap-4">
                            <p className={'text-[1.9rem]'}>Join</p>

                            <button
                                onClick={() => setIsNewsModalOpen(true)}
                                className={'w-[3rem] flex justify-center items-center p-3 bg-[#1D4A5F] rounded-full'}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                     className="size-6">
                                    <path
                                        d="M5.25 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM2.25 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM18.75 7.5a.75.75 0 0 0-1.5 0v2.25H15a.75.75 0 0 0 0 1.5h2.25v2.25a.75.75 0 0 0 1.5 0v-2.25H21a.75.75 0 0 0 0-1.5h-2.25V7.5Z" />
                                </svg>
                            </button>

                            <AnimatedModal
                                isOpen={isNewsModalOpen}
                                onClose={() => setIsNewsModalOpen(false)}
                                position="top-right"
                            >
                                <NewsLetter />
                            </AnimatedModal>
                        </div>
                    </nav>

                    <div className="flex flex-col w-11/12 items-center ">
                        <div className="text-[30px] md:text-[80px] font-bold text-light">COMING SOON</div>
                    </div>


                    <footer className="flex w-full p-10 gap-10 text-light justify-between items-center flex-wrap">
                        <div className="flex flex-row items-center gap-4">
                            <button
                                onClick={() => setIsContactModalOpen(true)}
                                className={'w-[3rem] flex justify-center items-center p-3 bg-[#1D4A5F] rounded-full'}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                     className="size-6">
                                    <path fillRule="evenodd"
                                          d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                                          clipRule="evenodd" />
                                </svg>
                            </button>
                            <p className={'text-[2rem]'}>Contact</p>

                            <AnimatedModal
                                isOpen={isContactModalOpen}
                                onClose={() => setIsContactModalOpen(false)}
                                position="bottom-left"
                            >
                                <ContactUs />
                            </AnimatedModal>
                        </div>

                        <div className="flex flex-row-reverse md:flex-row items-center gap-4">
                            <p className={'text-[2rem]'}>Gallery</p>

                            <AnimatedModal
                                isOpen={isGalleryModalOpen}
                                onClose={() => setIsGalleryModalOpen(false)}
                                position="bottom-right"
                            >
                                <Gallery />
                            </AnimatedModal>

                            <button
                                onClick={() => setIsGalleryModalOpen(true)}
                                className={'w-[3rem] h-[3rem] text-5xl flex justify-center text-light items-center p-2 bg-[#1D4A5F] rounded-full'}>
                                <PiDotsNineBold />
                            </button>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    );
}