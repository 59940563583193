import React from 'react';
import { motion } from 'framer-motion';
import { XIcon } from '@heroicons/react/solid';

interface ModalProps {
    children: React.ReactNode;
    isOpen: boolean;
    onClose: () => void;
    position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
}

const backdropVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
};

const modalVariants = {
    hidden: (position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right') => {
        let origin;
        switch (position) {
            case 'top-left':
                origin = 'top left';
                break;
            case 'top-right':
                origin = 'top right';
                break;
            case 'bottom-left':
                origin = 'bottom left';
                break;
            case 'bottom-right':
                origin = 'bottom right';
                break;
            default:
                origin = 'center';
                break;
        }
        return {
            scale: 0,
            opacity: 0,
            transformOrigin: origin,
            borderRadius: '50%',
        };
    },
    visible: {
        scale: 1,
        opacity: 1,
        borderRadius: '0rem', // Adjust border-radius as needed
        transition: {
            type: 'spring',
            stiffness: 300,
            damping: 30,
        },
    },
};

const Modal: React.FC<ModalProps> = ({ children, isOpen, onClose, position }) => {
    return (
        <>
            {isOpen && (
                <motion.div
                    className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={backdropVariants}
                    onClick={onClose}
                >
                    <motion.div
                        className="fixed inset-0 bg-[#282828] p-4 rounded-lg shadow-lg z-60 overflow-y-auto"
                        custom={position}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        variants={modalVariants}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="p-4 flex justify-end w-full">
                            <XIcon onClick={onClose} className={'w-14 cursor-pointer text-white'} />
                        </div>
                        {children}
                    </motion.div>
                </motion.div>
            )}
        </>
    );
};

export default Modal;
