import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/solid';

const images = [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1].map((_, index) => {
    return require(`../assets/photos/${index + 1}.jpg`);
});

const variants = {
    enter: (direction: number) => ({
        x: direction > 0 ? 600 : -600,
        scale: 0.4,
    }),
    center: {
        x: 0,
        scale: 1,
        transition: {
            duration: 0.5,
        },
    },
    exit: (direction: number) => ({
        x: direction < 0 ? 600 : -600,
        scale: 0.1,
        opacity: 0,
        transition: {
            duration: 0.5,
        },
    }),
};

const Slider: React.FC = () => {
    const [[page, direction], setPage] = React.useState([0, 0]);

    const paginate = (newDirection: number) => {
        setPage([page + newDirection, newDirection]);
    };

    const imageIndex = (page + images.length) % images.length;
    const prevImageIndex = (page - 1 + images.length) % images.length;
    const nextImageIndex = (page + 1) % images.length;

    return (
        <div>
            <div className="relative overflow-hidden w-full max-w[1200px] h-[300px] md:h-[700px] bg-red-5 flex flex-row items-center justify-center">
                <motion.img
                    src={images[prevImageIndex]}
                    variants={{
                        enter: (direction: number) => ({
                            x: direction > 0 ? 600 : -600,
                            scale: 0.4,
                        }),
                    }}
                    className="ml-20 hidden md:block absolute rounded left-0  w-1/5 h-auto object-cover"
                />

                <AnimatePresence initial={false} custom={direction}>
                    <motion.img
                        key={page}
                        src={images[imageIndex]}
                        custom={direction}
                        variants={variants}
                        initial="enter"
                        animate="center"
                        exit="exit"
                        className="absolute w-11/12 md:w-4/12 rounded overflow-clip h-auto object-cover"
                    />
                </AnimatePresence>

                <motion.img
                    src={images[nextImageIndex]}
                    className="mr-20 hidden md:block absolute rounded right-0 w-1/5 h-auto object-cover"
                />
                <div className="absolute top-1/2 transform -translate-y-1/2 w-full flex justify-between items-center px-4">
                    <button
                        onClick={() => paginate(-1)}
                        className="bg-white bg-opacity-50 py-6 rounded p-2 z-10"
                    >
                        <ArrowLeftIcon className={'w-10'} />
                    </button>
                    <button
                        onClick={() => paginate(1)}
                        className="bg-white bg-opacity-50 py-6 rounded p-2 z-10"
                    >
                        <ArrowRightIcon className={'w-10'} />
                    </button>
                </div>
            </div>
            <div className="w-full p-2 flex mt-5 justify-center items-center gap-2">
                {images.map((_, index) => {
                    return (
                        <div className={`w-3 h-3 rounded-full ${index === imageIndex ? 'bg-white' : 'border border-white'}`}>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Slider;
