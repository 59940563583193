import { LocationMarkerIcon, PhoneIcon} from '@heroicons/react/solid';
import email from '@emailjs/browser';
import {ChangeEvent, FormEvent, useState} from "react";

export default function ContactUs() {

    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        message: '',
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const { name, phone, email, message } = formData;
        const mailtoLink = `mailto:info@wakafitness.com?subject=${encodeURIComponent('Get In touch')}&body=Name:%20${encodeURIComponent(name)}%0D%0APhone:%20${encodeURIComponent(phone)}%0D%0AEmail:%20${encodeURIComponent(email)}%0D%0AMessage:%20${encodeURIComponent(message)}`;

        window.open(mailtoLink, '_blank');

        setFormData({
            name: '',
            phone: '',
            email: '',
            message: '',
        });
    };

    return (
        <div className={'flex flex-col w-full gap-2 md:gap-5 justify-around items-center '}>
            <div className="w-full flex justify-center items-center text-lg md:text-xl">CONTACT US</div>
            <div className="w-full flex justify-center items-center text-xl md:text-6xl">Get in Touch</div>
            <div className="w-11/12 md:w-8/12 p-5 justify-center flex flex-wrap rounded-[3rem] bg-[#1D4A5F]">
                <div className="flex md:w-1/2  flex-row gap-3 p-3 md:p-6">
                    <div className="">
                        <LocationMarkerIcon className={'w-7'} />
                    </div>
                    <div className="flex flex-col">
                        <p className={' text-md md:text-lg'}>OUR LOCATION</p>
                        <p className={' text-sm md:text-md'}>WAKA Town: KN 72 St Kigali, Rwanda</p>
                        <p className={' text-sm md:text-md'}>WAKA Kimi: 18 KG 674 St Kigali, Rwanda</p>
                    </div>
                </div>

                <div className="flex  flex-row gap-3 p-3 md:p-6">
                    <div className="">
                        <PhoneIcon className={'w-7'} />
                    </div>
                    <div className="flex flex-col">
                        <p className={'text-lg'}>Phone</p>
                        <p className={'text-md'}>WAKA Town: +250 783 189 029</p>
                        <p className={'text-md'}>WAKA Kimi: +250 786 601 749</p>
                    </div>
                </div>
            </div>
            <div className="w-full my-5 flex justify-center items-center text-xl md:text-6xl">Drop us a Line</div>
            <form onSubmit={handleSubmit} className=" w-11/12 md:w-8/12 grid grid-cols-1 md:grid-cols-3 gap-3 gap-y-5">
                <div className="overflow-clip col-span-3 md:col-span-1 w-full flex flex-row rounded-full border-2 mt-5">
                    <input placeholder={'Name'} type="text"
                           name={'name'}
                           value={formData.name}
                           onChange={handleChange}
                           required={true}
                           className={' md:p-5 p-3 bg-transparent w-full md:text-xl focus:outline-none border-none active:outline-none focus:border-none focus:ring-0'}/>
                </div>
                <div className="overflow-clip col-span-3 md:col-span-1 w-full flex flex-row rounded-full border-2 mt-5">
                    <input placeholder={'Email'} type="email"
                           name={'email'}
                           value={formData.email}
                           onChange={handleChange}
                           required={true}
                           className={' md:p-5 p-3 bg-transparent w-full md:text-xl focus:outline-none border-none active:outline-none focus:border-none focus:ring-0'}/>
                </div>
                <div className="overflow-clip col-span-3 md:col-span-1 w-full flex flex-row rounded-full border-2 mt-5">
                    <input placeholder={'Phone'} type="text"
                           name={'phone'}
                           value={formData.phone}
                           onChange={handleChange}
                           required={true}
                           className={' md:p-5 p-3 bg-transparent w-full md:text-xl focus:outline-none border-none active:outline-none focus:border-none focus:ring-0'}/>
                </div>

                <div className="overflow-clip col-span-3 min-h-[120px] w-full flex flex-row rounded-lg border-2 mt-5">
                    <textarea placeholder={'Message'}
                              name={'message'}
                              value={formData.message}
                              onChange={handleChange}
                              required={true}
                              className={'md:p-5 p-3 bg-transparent w-full md:text-xl focus:outline-none border-none active:outline-none focus:border-none focus:ring-0'}/>
                </div>

                <div className="w-full col-span-3 mt-5 justify-center items-center flex">
                    <button type={'submit'} className={'bg-[#1D4A5F] text-xl font-semibold p-5 px-10 w-36 flex rounded-full justify-center items-center'}>
                        Submit
                    </button>
                </div>

            </form>
        </div>
    );
}